@tailwind base;
@tailwind components;
@tailwind utilities;


.form-style{
  @apply w-full rounded-lg border border-borderColor focus:ring-primary focus:border-primary mb-4
}
@layer base {
  @font-face {
    font-family: 'Aeonik';
    font-style: normal;
    font-weight: 100; /* Thin */
    src: url(/public/Aeonik/Aeonik-Air.otf) format('opentype');
  }
  @font-face {
    font-family: 'Aeonik';
    font-style: normal;
    font-weight: 200; /* Light */
    src: url(/public/Aeonik/Aeonik-Light.otf) format('opentype');
  }
  
  @font-face {
    font-family: 'Aeonik';
    font-style: normal;
    font-weight: 300; /* Medium */
    src: url(/public/Aeonik/Aeonik-Medium.otf) format('opentype');
  }
  
  @font-face {
    font-family: 'Aeonik';
    font-style: normal;
    font-weight: 400; /* Regular */
    src: url(/public/Aeonik/Aeonik-Regular.otf) format('opentype');
  }
  
  @font-face {
    font-family: 'Aeonik';
    font-style: normal;
    font-weight: 500; /* Bold */
    src: url(/public/Aeonik/Aeonik-Bold.otf) format('opentype');
  }
  
  @font-face {
    font-family: 'Aeonik';
    font-style: normal;
    font-weight: 700; /* Black */
    src: url(/public/Aeonik/Aeonik-Black.otf) format('opentype');
  }
  
  
}
